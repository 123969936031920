import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mapa_sv = _resolveComponent("mapa-sv")
  const _component_territory_picker = _resolveComponent("territory-picker")
  const _component_cyan_page_wrapper = _resolveComponent("cyan-page-wrapper")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_cyan_page_wrapper, {
        title: "Elegir partido judicial",
        burst: true,
        backRoute: "/partyLevels",
        nav1: "MODO PARTIDO"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_mapa_sv, {
            nextRoute: "/partyList/",
            key: 'mapaPickDepto'
          }),
          _createVNode(_component_territory_picker, { nextRoute: "/partyList/" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}